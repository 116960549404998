import { store } from 'src/store'

let sidebarArr = [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-layout-11',
    path: '/data/overview'
  },
];

const intervalId = setInterval(() => {
  if (store.irisData && store.irisData.user.is_staff) {
    sidebarArr.push({
      name: 'Activity Log',
      icon: 'nc-icon nc-user-run',
      path: '/data/activity'
    });
    clearInterval(intervalId); // Clear the interval to stop the loop
  }
  else if (store.irisData && !store.irisData.user.is_staff) clearInterval(intervalId);
}, 2000);

export default sidebarArr
