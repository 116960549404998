<template>
  <div class="row">

    <div class="col-xl-1 col-lg-2 col-md-3">
    </div>
    <div class="col-xl-10 col-lg-8 col-md-6">
      <create-profile-form>

      </create-profile-form>
    </div>
    <div class="col-xl-1 col-lg-2 col-md-3">
    </div>

  </div>
</template>
<script>
  import CreateProfileForm from './UserProfile/CreateProfileForm.vue'
  import UserCard from './UserProfile/UserCard.vue'
  import MembersCard from './UserProfile/MembersCard.vue'

  export default {
    components: {
      CreateProfileForm,
      UserCard,
      MembersCard
    }
  }

</script>
<style>

</style>
