<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">System Status</h4>
    </div>
    <div class="card-body">
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createStatus)" autocomplete="off">
        <div class="row">
          <div class="col-md-12">
            <ValidationProvider
              name="status"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input 
                autocomplete="off"
                :error="failed ? 'The Status field is required': null"
                :hasSuccess="passed"
                name="status"
                label="Current (required)"
                v-model="user.status"
              >
                <el-select class="select-danger"
                           size="large"
                           v-model="user.status"
                           placeholder="Required *">
                      <el-option v-for="option in status_options"
                               class="select-danger"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-warning btn-fill btn-wd" v-if="user.status !== store.irisData.system_status">
            Update Status
          </button>
          <button type="submit" disabled class="btn btn-disabled btn-fill btn-wd" v-else>
            Update Status
          </button>
        </div>

        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    data () {
      return {
        store,
        status_options: [{label:'OK',value:1},{label:'Data Delay',value:2},{label:'Under Maintenance',value:3},{label:'Rebuilding Database',value:4}],
        user: {
          user_id: '',
          status: '',
        }
      }
    },
    methods: {
      setProfile () {
        this.user.user_id = store.irisData.user.id
        this.user.status = store.irisData.system_status
      },
      async createStatus () {
        const userDetails = {},
        tmpDateTime = (new Date()).toISOString().split('T'),
        tmpDate = tmpDateTime[0],
        tmpTime = tmpDateTime[1].split('.')[0],
        cleanDateTime = [tmpDate, tmpTime].join(' ');

        // Populate details
        userDetails.user_id = this.user.user_id
        userDetails.status = this.user.status
        userDetails.cdatetime = cleanDateTime

        //console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await store.createSystemStatus(userDetails)
        if (boolCheck) {
            this.$router.push({'name':'Overview'})

            alert('System Status updated successfully!')
        } else {
            alert('SYSTEM STATUS is incorrect!')
        }
      },
    },
    mounted () {
      this.setProfile()

      //console.log({user:this.user, store})

      if (store.irisData.sessionFilters) store.irisData.sessionFilters = false      
    },
  }

</script>
<style>

</style>
