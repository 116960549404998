var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProfile)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"type":"text","label":"Company","disabled":true,"placeholder":"Mundial Media"},model:{value:(_vm.user.init_irisprofile),callback:function ($$v) {_vm.$set(_vm.user, "init_irisprofile", $$v)},expression:"user.init_irisprofile"}})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"email|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'Email must be at least 5 characters': null,"hasSuccess":passed,"name":"email","label":"Email","disabled":true,"placeholder":"Email"},model:{value:(_vm.user.new_email),callback:function ($$v) {_vm.$set(_vm.user, "new_email", $$v)},expression:"user.new_email"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Password must be at least 2 characters': null,"hasSuccess":passed,"name":"firstname","label":"First Name","placeholder":"First Name"},model:{value:(_vm.user.new_first_name),callback:function ($$v) {_vm.$set(_vm.user, "new_first_name", $$v)},expression:"user.new_first_name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"lastname","rules":"min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Last Name must be at least 1 character': null,"hasSuccess":passed,"name":"lastname","label":"Last Name","placeholder":"Last Name"},model:{value:(_vm.user.new_last_name),callback:function ($$v) {_vm.$set(_vm.user, "new_last_name", $$v)},expression:"user.new_last_name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Password field is required': null,"hasSuccess":passed,"name":"password","label":"Current Password (required)","placeholder":"Current Password..."},model:{value:(_vm.user.init_password),callback:function ($$v) {_vm.$set(_vm.user, "init_password", $$v)},expression:"user.init_password"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"newpassword","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'New Password must be at least 5 characters': null,"hasSuccess":passed,"name":"newpassword","label":"New Password (optional)","placeholder":"New Password..."},model:{value:(_vm.user.new_password),callback:function ($$v) {_vm.$set(_vm.user, "new_password", $$v)},expression:"user.new_password"}})]}}],null,true)})],1)]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-info btn-fill btn-wd",attrs:{"type":"submit"}},[_vm._v(" Update Profile ")])]),_c('div',{staticClass:"clearfix"})])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"title"},[_vm._v("Edit Profile")])])}]

export { render, staticRenderFns }