<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center">Set New Password</h3>

                  <fg-input v-model="form.email" addon-left-icon="nc-icon nc-single-02"
                            placeholder="Email..."></fg-input>

                  <fg-input v-model="form.password" addon-left-icon="nc-icon nc-key-25" placeholder="Password"
                            type="password"></fg-input>

                  <br>
		
		<!-- OG checkbox
                  <p-checkbox>
                    Subscribe to newsletter
                  </p-checkbox>
		-->


                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3">Enter</p-button>
                </card>
              </form>
            </div>
          </div>
        </div>


        <!-- cookie policy modal -->
        <modal :show.sync="modals.cookiePolicy"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Cookie Policy</h3>
          <cookie-policy class="iris-modal"></cookie-policy>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.cookiePolicy = false">Close</p-button>
          </div>
        
        </modal>


        <!-- privacy notice modal -->
        <modal :show.sync="modals.privacyNotice"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Privacy Notice</h3>
          <privacy-notice class="iris-modal"></privacy-notice>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.privacyNotice = false">Close</p-button>
          </div>
        
        </modal>


        <!-- terms conditions modal -->
        <modal :show.sync="modals.termsConditions"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Terms & Conditions</h3>
          <terms-conditions class="iris-modal"></terms-conditions>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.termsConditions = false">Close</p-button>
          </div>
        
        </modal>


        <app-footer :toggleCookie="toggleCookie"
                    :togglePrivacy="togglePrivacy"
                    :toggleTerms="toggleTerms"></app-footer>


        <div class="full-page-background" style="background-image: url(static/img/background/iris_bkg.webp) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button, Modal } from 'src/components/UIComponents';
  import CookiePolicy from 'src/components/Dashboard/CookiePolicy'
  import PrivacyNotice from 'src/components/Dashboard/PrivacyNotice'
  import TermsConditions from 'src/components/Dashboard/TermsConditions'
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import axios from 'axios'
  import { store } from 'src/store'

  export default {
    components: {
      CookiePolicy,
      PrivacyNotice,
      TermsConditions,
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      Modal
    },
    methods: {
      toggleCookie() {
        this.modals.cookiePolicy = !this.modals.cookiePolicy
      },
      togglePrivacy() {
        this.modals.privacyNotice = !this.modals.privacyNotice
      },
      toggleTerms() {
        this.modals.termsConditions = !this.modals.termsConditions
      },
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      async login() {
        // handle login here
	const self = this;

        // Kept for reference for Forms
	//let UserForm = new FormData();
	//UserForm.append('email',self.form.email)
	//UserForm.append('password',self.form.password)

	// GET User & Update State
	let params = Object.assign({}, self.form)
	await this.store.loginIris(params)

        // Redirect
        if(this.store.irisData){
          this.$router.push({'name':'Overview'})
        } else {
          alert('EMAIL or PASSWORD is incorrect!')
        }
      }
    },
    data() {
      return {
	store,
        modals: {
          cookiePolicy: false,
          privacyNotice: false,
          termsConditions: false
        },
        form: {
          email: '',
          password: ''
        }
      }
    },
    mounted() {
      console.log({router:this.$router});
    },
    beforeDestroy() {
      this.closeMenu()
      this.modals.cookiePolicy = false
      this.modals.privacyNotice = false
      this.modals.termsConditions = false
    }
  }
</script>
<style>
.iris-modal {
  max-height: 48vh;
  overflow-y: auto;
  margin-right: -1.5em;
  margin-left: -1em;
  zoom: 72%;
}
</style>
