<template>
  <div class="row">

    <div class="col-xl-1 col-lg-2 col-md-3">
    </div>
    <div class="col-xl-10 col-lg-8 col-md-6">
      <system-status-form>

      </system-status-form>
    </div>
    <div class="col-xl-1 col-lg-2 col-md-3">
    </div>

  </div>
</template>
<script>
  import SystemStatusForm from './UserProfile/SystemStatusForm.vue'

  export default {
    components: {
      SystemStatusForm,
    }
  }

</script>
<style>

</style>
