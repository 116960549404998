<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <nav class="footer-nav">
          <ul>
            <li>
              <a href="https://www.mundialmedia.com" target="_blank" rel="noopener">Mundial Media</a>
            </li>


            <!-- TEMP, working correctly
            <li>
              <a class="iris-a" @click="toggleCookie()">Cookie Policy</a>
            </li>
            END -->


            <li>
              <a class="iris-a" @click="togglePrivacy()">Privacy Notice</a>
            </li>
          </ul>
        </nav>
        <div class="credits ml-auto">
          <div class="copyright">
            &copy; {{ new Date().getFullYear() }} Mundial Media - Platform ver {{ IRIS_VERSION }}
          </div>
        </div>


        <!-- OG text
        <div class="credits ml-auto">
          <div class="copyright">
            &copy; Created with
            <i class="fa fa-heart heart"></i> by <a href="https://www.mundialmedia.com" target="_blank" rel="noopener">Mundial Media</a>.
          </div>
        </div>
        END -->


      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'main-footer',
    props: ['toggleCookie','togglePrivacy','toggleTerms'],
    data () {
      return {
        IRIS_VERSION,
      }
    },
  }
</script>
<style>
.iris-a {
  cursor: pointer;
}
</style>
