<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Edit Profile</h4>
    </div>
    <div class="card-body">
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(updateProfile)">
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Company"
                      :disabled="true"
                      placeholder="Mundial Media"
                      v-model="user.init_irisprofile">
            </fg-input>
          </div>



          <!-- OG field
          <div class="col-md-3">
            <ValidationProvider
              name="username"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      :error="failed ? 'Username must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="username"
                      label="Username"
                      placeholder="Username"
                      v-model="user.new_username">
              </fg-input>
            </ValidationProvider>
          </div>
          -->



          <div class="col-md-6">


            <!-- REMOVED email not needed
            END -->

            <ValidationProvider
              name="email"
              rules="email|min:5"
              v-slot="{ passed, failed }"
            >
              <fg-input type="email"
                      :error="failed ? 'Email must be at least 5 characters': null"
                      :hasSuccess="passed"
                      name="email"
                      label="Email"
                      :disabled="true"
                      placeholder="Email"
                      v-model="user.new_email">
              </fg-input>
            </ValidationProvider>


          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="firstname"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      :error="failed ? 'Password must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="firstname"
                      label="First Name"
                      placeholder="First Name"
                      v-model="user.new_first_name">
              </fg-input>
            </ValidationProvider>
          </div>
            <div class="col-md-6">
            <ValidationProvider
              name="lastname"
              rules="min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      :error="failed ? 'Last Name must be at least 1 character': null"
                      :hasSuccess="passed"
                      name="lastname"
                      label="Last Name"
                      placeholder="Last Name"
                      v-model="user.new_last_name">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="password"
              rules="required|min:5"
              v-slot="{ passed, failed }"
            >
              <fg-input type="password"
                      :error="failed ? 'The Password field is required': null"
                      :hasSuccess="passed"
                      name="password"
                      label="Current Password (required)"
                      placeholder="Current Password..."
                      v-model="user.init_password">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider
              name="newpassword"
              rules="min:5"
              v-slot="{ passed, failed }"
            >
              <fg-input type="password"
                      :error="failed ? 'New Password must be at least 5 characters': null"
                      :hasSuccess="passed"
                      name="newpassword"
                      label="New Password (optional)"
                      placeholder="New Password..."
                      v-model="user.new_password">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Update Profile
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);  

  export default {
    data () {
      return {
        store,
        user: {
          init_id: '',
          init_irisprofile: '',
          init_username: '',
          init_email: '',
          init_first_name: '',
          init_last_name: '',
          init_password: '',

          new_username: '',
          new_email: '',
          new_first_name: '',
          new_last_name: '',
          new_password: '',
        }
      }
    },
    methods: {
      setProfile () {
          this.user.init_id = store.irisData.user.id
          this.user.init_irisprofile = store.irisData.user.irisprofile
          this.user.init_username = store.irisData.user.username
          this.user.init_email = store.irisData.user.email
          this.user.init_first_name = store.irisData.user.first_name
          this.user.init_last_name = store.irisData.user.last_name

          this.user.new_username = store.irisData.user.username
          this.user.new_email = store.irisData.user.email
          this.user.new_first_name = store.irisData.user.first_name
          this.user.new_last_name = store.irisData.user.last_name
      },
      async updateProfile () {
        const userUpdates = {}
        let updateFlag = false
        userUpdates.og_username = this.user.init_username
        userUpdates.og_password = this.user.init_password

        //if (this.user.new_username.length && this.user.init_username !== this.user.new_username) {
        //    userUpdates.username = this.user.new_username
        //    updateFlag = true
        //}
 
        //if (this.user.new_email.length && this.user.init_email !== this.user.new_email) {
        //    userUpdates.email = this.user.new_email
        //    userUpdates.username = this.user.new_email
        //    updateFlag = true
        //}

        if (this.user.new_first_name.length && this.user.init_first_name !== this.user.new_first_name) {
            userUpdates.first_name = this.user.new_first_name
            updateFlag = true
        }

        if (this.user.new_last_name.length && this.user.init_last_name !== this.user.new_last_name) {
            userUpdates.last_name = this.user.new_last_name
            updateFlag = true
        }

        if (this.user.new_password.length && this.user.init_password !== this.user.new_password) {
            userUpdates.password = this.user.new_password
            updateFlag = true
        }

        //console.log('Your data: ' + JSON.stringify(this.user))

        if (updateFlag) {
            
            //console.log('Your updates: ' + JSON.stringify(userUpdates))
            //console.log('Update! ',{updateFlag})

            const boolCheck = await this.store.updateIris(userUpdates)
            if (boolCheck === 'EXISTING_USERNAME') {
                alert('EMAIL already exists!')
            } else if (boolCheck) {
                alert('Iris updated successfully!')
                this.user.init_username = store.irisData.user.username
                this.user.init_email = store.irisData.user.email
                this.user.init_first_name = store.irisData.user.first_name
                this.user.init_last_name = store.irisData.user.last_name
            } else {
                alert('EMAIL or PASSWORD is incorrect!')
            }
        }

      }
    },
    mounted () {
      this.setProfile()

      if (store.irisData.sessionFilters) store.irisData.sessionFilters = false
    }
  }

</script>
<style>

</style>
